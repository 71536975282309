import request from "@/core/services/axios";
import qs from "qs";
// 获取按人分配的任务列表接口
export function exammarkTeacherList(query) {
  return request({
    url: "/exam/exammark/teacher/list",
    method: "get",
    params: query,
  });
}

// 按班级批阅-重置批阅老师
export function exammarkResetClass(query) {
  return request({
    url: "/exam/exammark/reset/class",
    method: "get",
    params: query,
  });
}

// 阅卷分配获取单个学校的校任务量
export function getSchoolWorkload(query) {
  return request({
    url: "/exam/examteacher/school/workload",
    method: "get",
    params: query,
  });
}

// 获取单个学校单道题的阅卷教师
export function teacherSchoolList(query) {
  return request({
    url: "/exam/exammark/teacher/school/list",
    method: "get",
    params: query,
  });
}

// 查询学校分组是否分配完毕||查询参考学校数量是否发生改变
export function groupValid(query) {
  return request({
    url: "/exam/exammark/group/valid",
    method: "get",
    params: query,
  });
}

// 导出阅卷分工/exam/exammark/export/outline
export function exammarkExportOutline(query) {
  return request({
    url: "/exam/exammark/export/outline",
    method: "get",
    params: query,
  });
}

// 导出阅卷分工提纲
export function exammarkExportOutMark(query) {
  return request({
    url: "/exam/exammark/export/mark",
    method: "get",
    params: query,
  });
}

// 获取按班级分配的任务列表接口
export function exammarkClassList(query) {
  return request({
    url: "/exam/exammark/class/list",
    method: "get",
    params: query,
  });
}

// 导出阅卷分工上传模板
export function exammarkExportTemplate(query) {
  return request({
    url: "/exam/exammark/export/template",
    method: "get",
    params: query,
  });
}

// 联考-导出阅卷分工上传模板
export function exammarkExportUniontemplate(query) {
  return request({
    url: "/exam/exammark/export/uniontemplate",
    method: "get",
    params: query,
  });
}

// 获取正常卡人数和考生人数
export function exammarkSheet(query) {
  return request({
    url: "/exam/exammark/sheet",
    method: "get",
    params: query,
  });
}

// 获取正常卡人数和考生人数 联考
export function exammarkUnionSheet(query) {
  return request({
    url: "/exam/exammark/unionsheet",
    method: "get",
    params: query,
  });
}

//设置绑定批阅-查看阅卷区域
export function exammarkRegion(query) {
  return request({
    url: "/exam/exammark/region",
    method: "get",
    params: query,
  });
}

// 设置评分规则 - 设置给分方式 - 支持批量设置;
export function exammarkRuleScore(data) {
  return request({
    url: "/exam/exammark/rule/score",
    method: "put",
    data: data,
  });
}

// 是否允许重复阅卷
export function exammarkExamRepeat(query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/exam/exampaper/exam/repeat?" + dataObj,
    method: "put",
  });
}

// 设置阅卷老师
export function exammarkSaveTeacher(data) {
  return request({
    url: "/exam/exammark/save/teacher",
    method: "put",
    data: data,
  });
}

// 按班级批阅-设置-删除阅卷老师接口
export function exammarkSaveClass(data) {
  return request({
    url: "/exam/exammark/save/class",
    method: "post",
    data: data,
  });
}

// 复制阅卷老师
export function exammarkCopyTeacher(data) {
  return request({
    url: "/exam/exammark/copy/teacher",
    method: "put",
    params: data,
  });
}

//  清除阅卷老师
export function exammarkClearTeacher(data) {
  return request({
    url: "/exam/exammark/clear/teacher",
    method: "put",
    params: data,
  });
}

// 联考  清除阅卷老师
export function clearUnionteacher(data) {
  return request({
    url: "/exam/exammark/clear/unionteacher",
    method: "put",
    params: data,
  });
}

// 切换考试阅卷分工类型
export function exammarkMarkType(data) {
  return request({
    url: "/exam/exammark/mark/type",
    method: "put",
    params: data,
  });
}

// 联考-阅卷分配-清空单道题的阅卷教师
export function singleteacher(data) {
  return request({
    url: "/exam/exammark/clear/singleteacher",
    method: "delete",
    params: data,
  });
}

// 切换考试阅卷分工类型
export function exammarkCalculateWorkload(data) {
  return request({
    url: "/exam/exammark/calculate/workload",
    method: "put",
    data: data,
  });
}

// 保存-修改学校分组列表
export function schoolGroup(data) {
  return request({
    url: "/exam/exammark/school/group",
    method: "post",
    data: data,
  });
}

// 联考-设置学校任务量-保存任务量
export function setschoolWorkload(data) {
  return request({
    url: "/exam/exammark/school/workload",
    method: "post",
    data: data,
  });
}

// 获取学校分组列表
export function groupList(query) {
  return request({
    url: "/exam/exammark/school/group/list",
    method: "get",
    params: query,
  });
}

// 获取主观题列表
export function questionList(query) {
  return request({
    url: "/exam/exammark/question/list",
    method: "get",
    params: query,
  });
}

// 获取主观题列表  带参数
export function questionDetail(query) {
  return request({
    url: "/exam/exammark/question/detail",
    method: "get",
    params: query,
  });
}

// 分项给分-设置打分区域-获取图片
export function pointArea(query) {
  return request({
    url: "/exam/exammark/point/area",
    method: "get",
    params: query,
  });
}

// 获取图片裁切
export function pointImg(query) {
  return request({
    url: "/exam/exammark/point/img",
    method: "post",
    data: query,
  });
}

// 联考-设置学校任务量-学校任务量模板下载
export function workloadTemplate(query) {
  return request({
    url: "/exam/exammark/school/workload/template",
    method: "get",
    params: query,
  });
}

// 按学校查看阅卷分工任务列表
export function schoolExamwork(query) {
  return request({
    url: "/exam/exammark/school/examwork",
    method: "get",
    params: query,
  });
}

// 获取学校任务量列表
export function schoolWorkload(query) {
  return request({
    url: "/exam/exammark/school/workload",
    method: "get",
    params: query,
  });
}

// 获取分项给分列表
export function exammarkPoint(query) {
  return request({
    url: "/exam/exammark/point",
    method: "get",
    params: query,
  });
}

// 联考-试卷修改阅卷模式；修改抽卷方式
export function exampaperMode(query) {
  return request({
    url: "/exam/exampaper/mode",
    method: "get",
    params: query,
  });
}

// 联考--获取分组下的学校列表
export function schoollistbygroupid(query) {
  return request({
    url: "/exam/exammark/get/schoollistbygroupid",
    method: "get",
    params: query,
  });
}

// 联考--获取分组任务量列表
export function groupWorkload(query) {
  return request({
    url: "/exam/exammark/group/workload",
    method: "get",
    params: query,
  });
}

// 联考--获取分组任务量列表
export function saveGroupWorkload(query) {
  return request({
    url: "/exam/exammark/group/workload",
    method: "post",
    data: query,
  });
}

// 联考-修改分组分配模式；修改分组抽卷方式
export function exampaperGroupMode(query) {
  return request({
    url: "/exam/exampaper/group/mode",
    method: "get",
    params: query,
  });
}

// 联考--获取学校任务量列表v2
export function schoolWorkloadv2(query) {
  return request({
    url: "/exam/exammark/school/workloadv2",
    method: "get",
    params: query,
  });
}

// 联考-导出阅卷分工
export function exportUnionmark(query) {
  return request({
    url: "/exam/exammark/export/unionmark",
    method: "get",
    params: query,
  });
}

// 联考-设置学校任务量-保存任务量V2
export function saveSchoolWorkloadv2(query) {
  return request({
    url: "/exam/exammark/school/workloadv2",
    method: "post",
    data: query,
  });
}

// 删除学校分组列表
export function delSchoolGroup(query) {
  return request({
    url: "/exam/exammark/school/group",
    method: "DELETE",
    params: query,
  });
}

// 删除分项给分数据
export function delExammarkPoint(query) {
  return request({
    url: "/exam/exammark/point",
    method: "DELETE",
    params: query,
  });
}

export function getType(data) {
  // 联考-考试抽卷方式设置
  return request({
    url: "/exam/exammark/get/type",
    method: "PUT",
    data: data,
  });
}

export function againcalculatescore(data) {
  // 重新计算平均分
  return request({
    url: "/mark/markinfo/againcalculatescore",
    method: "PUT",
    data: data,
  });
}

// 联考-设置学校任务量-导出任务量设置
export function exportWorkload(data) {
  return request({
    url: "/exam/exammark/export/workload",
    method: "post",
    data: data,
  });
}

// 保存分项给分列表数据
export function saveExammarkPoint(data) {
  return request({
    url: "/exam/exammark/point",
    method: "post",
    data: data,
  });
}

// 联考-获取学校分组列表
export function schoolGroupList(data) {
  return request({
    url: "/exam/exammark/school/group/list",
    method: "get",
    params: data,
  });
}

// 保存-修改学校分组列表
export function addSchoolGroup(data) {
  return request({
    url: "/exam/exammark/school/group",
    method: "post",
    data: data,
  });
}

// 删除学校分组列表
export function deleteGroup(data) {
  return request({
    url: "/exam/exammark/school/group",
    method: "DELETE",
    params: data,
  });
}
