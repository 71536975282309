var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ExamQuestionArea"},[_c('detailsHeader',{ref:"detailsHeader",attrs:{"query":{ examId: _vm.$route.query.code },"url":"ExamTaskRules"}}),_c('div',{staticClass:"details-box"},[_c('div',{staticClass:"score-item"},[_vm._v(" 设置打分区域： "),_c('el-radio-group',{on:{"change":function($event){return _vm.checkQuesiotn()}},model:{value:(_vm.pointId),callback:function ($$v) {_vm.pointId=$$v},expression:"pointId"}},_vm._l((_vm.exammarkPointItem),function(item,index){return _c('el-radio',{key:index,attrs:{"label":item.tId}},[_vm._v(" "+_vm._s(item.name ? item.name : "给分点" + (index + 1))+" ")])}),1),_c('el-button',{on:{"click":function($event){return _vm.reset()}}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("保存")])],1),_c('div',{staticClass:"img-box"},[_c('div',{ref:"markSheet",staticClass:"cavans-box"},[_c('div',{ref:"locationBox",attrs:{"id":"locationBox"},on:{"mousemove":_vm.mousemove}},[_c('canvas',{ref:"imgCanvas",attrs:{"id":"imgCanvas"}}),_c('canvas',{ref:"lineCanvas",attrs:{"id":"lineCanvas"},on:{"mousedown":_vm.mousedown}}),(
              _vm.exammarkPoint.points && _vm.exammarkPoint.points.width && _vm.proportion
            )?_c('div',{key:_vm.proportionKey,ref:"rTextareaWrap",staticClass:"operating-box edit-operating-box",style:({
              left: _vm.getline(_vm.exammarkPoint, 'left'),
              top: _vm.getline(_vm.exammarkPoint, 'top'),
              width: _vm.getline(_vm.exammarkPoint, 'width'),
              height: _vm.getline(_vm.exammarkPoint, 'height'),
            }),on:{"mousemove":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mousemoveTest($event)},"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mousedownTest($event)},"mouseup":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mouseupTest($event)},"mouseout":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mouseout()}}},[_c('div',{staticClass:"circle-box circle-left",on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMouseDown($event, 'left')}}}),_c('div',{staticClass:"circle-box circle-right",on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMouseDown($event, 'right')}}}),_c('div',{staticClass:"circle-box circle-top",on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMouseDown($event, 'top')}}}),_c('div',{staticClass:"circle-box circle-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMouseDown($event, 'bottom')}}})]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }