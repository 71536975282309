<template>
  <div class="ExamQuestionArea">
    <detailsHeader
      ref="detailsHeader"
      :query="{ examId: $route.query.code }"
      url="ExamTaskRules"
    ></detailsHeader>
    <div class="details-box">
      <div class="score-item">
        设置打分区域：
        <!-- {{ pointId }}{{ exammarkPointItem }} -->
        <el-radio-group v-model="pointId" @change="checkQuesiotn()">
          <el-radio
            v-for="(item, index) in exammarkPointItem"
            :key="index"
            :label="item.tId"
          >
            {{ item.name ? item.name : "给分点" + (index + 1) }}
          </el-radio>
        </el-radio-group>
        <el-button @click="reset()">重置</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >保存</el-button
        >
      </div>
      <div class="img-box">
        <div ref="markSheet" class="cavans-box">
          <div id="locationBox" ref="locationBox" @mousemove="mousemove">
            <canvas id="imgCanvas" ref="imgCanvas"></canvas>

            <canvas id="lineCanvas" ref="lineCanvas" @mousedown="mousedown">
            </canvas>
            <!-- 可拖动大小的盒子 -->
            <div
              v-if="
                exammarkPoint.points && exammarkPoint.points.width && proportion
              "
              ref="rTextareaWrap"
              :key="proportionKey"
              class="operating-box edit-operating-box"
              :style="{
                left: getline(exammarkPoint, 'left'),
                top: getline(exammarkPoint, 'top'),
                width: getline(exammarkPoint, 'width'),
                height: getline(exammarkPoint, 'height'),
              }"
              @mousemove.self="mousemoveTest($event)"
              @mousedown.self="mousedownTest($event)"
              @mouseup.self="mouseupTest($event)"
              @mouseout.self="mouseout()"
            >
              <div
                class="circle-box circle-left"
                @mousedown.stop="handleMouseDown($event, 'left')"
              ></div>
              <div
                class="circle-box circle-right"
                @mousedown.stop="handleMouseDown($event, 'right')"
              ></div>
              <div
                class="circle-box circle-top"
                @mousedown.stop="handleMouseDown($event, 'top')"
              ></div>
              <div
                class="circle-box circle-bottom"
                @mousedown.stop="handleMouseDown($event, 'bottom')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import { getObj } from "@/core/api/exam/exam";
import { pointArea, pointImg } from "@/core/api/exam/examCorrectTask";
import { getStore, setStore } from "@/core/util/store";
// 调整框的最大 最小值
const TextAreaWrap = {
  MaxHeight: 2000,
  MinHeight: 20,
  MaxWidth: 2000,
  MinWidth: 20,
};
export default {
  name: "ExamQuestionArea",
  components: { detailsHeader },
  data() {
    return {
      examInfo: {},
      examInformation: {},
      exammarkPointItem: [],
      question: {},
      indexQuesiton: {},
      exammarkPoint: {},
      lineData: {},
      proportion: 0,
      pointId: 0,
      proportionKey: 1,
      btnLoading: false,
    };
  },
  created() {
    this.examInfo = this.$route.query;
    this.getObj();
    this.init();
    this.getpointArea();
  },
  mounted() {
    // 绑定鼠标移动事件
    document.addEventListener("mousemove", this.mousemoveAll);
    document.addEventListener("mouseup", this.mouseupAll);
  },
  destroyed() {
    // 移除鼠标移动事件
    document.removeEventListener("mousemove", this.mousemoveAll);
    // 移除鼠标放开事件
    document.removeEventListener("mouseup", this.mouseupAll);
  },
  methods: {
    submit() {
      // let str = item.name ? item.name : "给分点" + (index + 1);
      // console.log(this.exammarkPointItem);
      let arr = [];
      this.exammarkPointItem.map((item, index) => {
        if (!item.points.width) {
          let str = item.name ? item.name : "给分点" + (index + 1);
          arr.push(str);
        }
      });
      if (arr.length == 0) {
        this.subArea();
      } else {
        const h = this.$createElement;
        this.$msgbox({
          title: "温馨提示",
          message: h("p", null, [
            h("span", null, "当前 "),
            h("span", { style: "color: red" }, arr.join(",")),
            h("span", null, "尚未框选区域,是否保存？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "保存",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              this.subArea(instance, done);
              // setTimeout(() => {
              //   this.$router.go(-1);
              //   done();
              // }, 1500);
            } else {
              done();
            }
          },
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    subArea(instance, done) {
      // this.imgUrl
      let array = this.exammarkPointItem.map((item) => {
        if (item.points.width) {
          let data = {
            tId: item.tId,
            points: item.points,
          };
          data.points.priority = 1;
          return data;
        }
      });
      array = array.filter((item) => item);
      let data = {
        url: this.imgUrl,
        areaArr: array,
      };
      // console.log(JSON.stringify(data));
      this.getArea(data, instance, done);
    },
    getArea(data, instance, done) {
      this.btnLoading = true;
      pointImg(data)
        .then((res) => {
          if (instance) {
            done();
            instance.confirmButtonLoading = false;
          }

          this.btnLoading = false;
          this.exammarkPointItem = this.exammarkPointItem.map((item) => {
            res.data.data.map((index) => {
              if (index.tId == item.tId) {
                index.pointsUrl = index.imgPath;
                Object.assign(item, index);
              }
              index.tId = Number(index.tId);
            });
            return item;
          });

          setStore({
            name: "exammarkPointItem",
            type: 1,
            content: this.exammarkPointItem,
          });
          this.$message({
            showClose: true,
            message: "保存成功!",
            type: "success",
          });
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 1500);
        })
        .catch(() => {
          this.btnLoading = false;
          if (instance) {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "保存";
          }
        });
    },
    // 重置
    reset() {
      const h = this.$createElement;
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h("span", null, "当前正在重置所有给分点区域， "),
          h("span", { style: "color: red" }, "重置后所有区域都将清空"),
          h("span", null, "是否继续？ "),
        ]),
        showCancelButton: true,
        confirmButtonText: "继续",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.exammarkPointItem = [...this.exammarkPointItem].map((item) => {
            item.points = {};
            item.pointsUrl = "";
            return item;
          });
          this.exammarkPoint.points = {};
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.setAllLineData();

          this.$message({
            showClose: true,
            message: "给分项区域已全部重置，可进行下一步操作！",
            type: "success",
          });
        })
        .catch(() => {});
    },
    showtips() {
      this.$alert("给分项区域已全部重置，可进行下一步操作！", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    checkQuesiotn() {
      this.exammarkPointItem.map((item) => {
        if (this.pointId == item.tId) {
          this.exammarkPoint = JSON.parse(JSON.stringify(item));
        }
      });
      this.proportionKey = new Date().getTime();
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
    },
    /**
     * 处理鼠标按下事件
     *
     * @param {MouseEvent} 鼠标事件
     */
    handleMouseDown(event, type) {
      // console.log(event);
      // console.log(type);
      this.editListType = type;
      // 禁止用户选择网页中文字
      document.onselectstart = () => false;
      // 禁止用户拖动元素
      document.ondragstart = () => false;

      // 保存鼠标最后移动的位置（Y轴）
      this.dragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: event.clientX,
      };

      // 绑定鼠标移动事件
      document.addEventListener("mousemove", this.handleMouseMove);
      // 绑定鼠标放开事件
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    /**
     * 处理鼠标移动事件
     *
     * @param {MouseEvent} 鼠标事件
     */
    handleMouseMove(event) {
      const { rTextareaWrap } = this.$refs;
      // console.log(rTextareaWrap);
      if (
        !rTextareaWrap ||
        !this.dragState ||
        !this.dragState.endMouseTop ||
        !this.dragState.endMouseLeft
      ) {
        return;
      }
      // 获取鼠标最后移动的位置（Y轴）
      const { endMouseTop, endMouseLeft } = this.dragState;
      const { scrollLeft, scrollTop } = this.$refs.markSheet;

      // 获取当前的高度
      const oldTextAreaHeight = rTextareaWrap.offsetHeight;
      const oldTextAreaWidth = rTextareaWrap.offsetWidth;
      // 当前位置到顶部
      let oldTextAreaTop = rTextareaWrap.offsetTop;
      // 到左边的距离
      let oldTextAreaLeft = rTextareaWrap.offsetLeft;
      // 新的框高度
      let newTextAreaHeight = 0;
      let newTextAreaWidth = 0;

      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - event.clientX) * 100).toString(), 10) / 100
      );
      // console.log(event);
      // 若鼠标向上移动
      if (endMouseTop < event.clientY) {
        // 发送框高度达到最大
        // if (oldTextAreaHeight >= TextAreaWrap.MaxHeight) {
        //   // 修改光标为可被向下移动
        //   // rTextareaWrap.style.cursor = "s-resize";
        //   return false;
        // }
        if (
          this.editListType == "top" &&
          oldTextAreaHeight == TextAreaWrap.MinHeight
        ) {
          newTextAreaHeight = oldTextAreaHeight;
          return;
        }
        // 计算新的发送框高度
        newTextAreaHeight = oldTextAreaHeight + distance;
        // 如果是再上边的线条
        // console.log(oldTextAreaTop);
        // console.log(distance);
        if (this.editListType == "top") {
          oldTextAreaTop = oldTextAreaTop + distance;
          newTextAreaHeight = oldTextAreaHeight - distance;
        }

        // console.log(oldTextAreaTop);
      } else {
        // 计算新的发送框高度
        newTextAreaHeight = oldTextAreaHeight - distance;
        if (this.editListType == "top") {
          oldTextAreaTop = oldTextAreaTop - distance;
          newTextAreaHeight = oldTextAreaHeight + distance;
          // console.log(event.clientY);
          if (event.clientY < 174 - scrollTop) {
            newTextAreaHeight = oldTextAreaHeight;
          }
        }
        // console.log(oldTextAreaTop);
      }
      // 若鼠标向右移动
      if (endMouseLeft < event.clientX) {
        if (
          oldTextAreaWidth == TextAreaWrap.MinWidth &&
          this.editListType == "left"
        ) {
          newTextAreaWidth = oldTextAreaWidth;
          // console.log("就是这个判断");
          return;
        }
        // 计算新的发送框高度
        newTextAreaWidth = oldTextAreaWidth + distanceLeft;
        if (this.editListType == "left") {
          oldTextAreaLeft = oldTextAreaLeft + distanceLeft;
          newTextAreaWidth = oldTextAreaWidth - distanceLeft;
        }
      } else {
        // 计算新的发送框高度
        newTextAreaWidth = oldTextAreaWidth - distanceLeft;
        if (this.editListType == "left") {
          oldTextAreaLeft = oldTextAreaLeft - distanceLeft;
          newTextAreaWidth = oldTextAreaWidth + distanceLeft;
          if (event.clientX < this.oldLeft - scrollLeft) {
            newTextAreaWidth = oldTextAreaWidth + distanceLeft;
          }
        }
      }

      // 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
      if (newTextAreaHeight > TextAreaWrap.MaxHeight) {
        newTextAreaHeight = TextAreaWrap.MaxHeight;
      }

      // 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
      if (newTextAreaHeight < TextAreaWrap.MinHeight) {
        newTextAreaHeight = TextAreaWrap.MinHeight;
      }

      // 兼容鼠标快速拖动的情况：新的发送框宽度不能超过最大值
      if (newTextAreaWidth > TextAreaWrap.MaxWidth) {
        newTextAreaWidth = TextAreaWrap.MaxWidth;
      }

      // 兼容鼠标快速拖动的情况：新的发送框宽度不能小于最小值
      if (newTextAreaWidth < TextAreaWrap.MinWidth) {
        newTextAreaWidth = TextAreaWrap.MinWidth;
      }
      // 判断下  如果边距为负值 添加直接最小值
      if (oldTextAreaTop < 1) {
        oldTextAreaTop = 1;
      }
      if (oldTextAreaLeft < 1) {
        oldTextAreaLeft = 1;
      }
      // 判断下left太多了
      if (oldTextAreaLeft >= this.viewWidth) {
        oldTextAreaLeft = this.viewWidth;
      }
      if (oldTextAreaTop >= this.viewHeight) {
        oldTextAreaTop = this.viewHeight;
      }
      // 判断下超过右边
      if (oldTextAreaLeft + newTextAreaWidth >= this.viewWidth) {
        newTextAreaWidth = this.viewWidth - oldTextAreaLeft - 1;
      }
      // 判断下超过高度
      if (oldTextAreaTop + newTextAreaHeight >= this.viewHeight) {
        newTextAreaHeight = this.viewHeight - oldTextAreaTop - 1;
      }
      // 修改发送框高度
      rTextareaWrap.style.height = newTextAreaHeight + "px";
      rTextareaWrap.style.width = newTextAreaWidth + "px";
      // 修改距离
      rTextareaWrap.style.top = oldTextAreaTop + "px";
      rTextareaWrap.style.left = oldTextAreaLeft + "px";
      // 修改光标为可移动
      this.newHeight = newTextAreaHeight;
      this.newWidth = newTextAreaWidth;
      this.newTop = oldTextAreaTop;
      this.newLeft = oldTextAreaLeft;

      // 更新鼠标最后移动的位置（Y轴）
      this.dragState.endMouseTop = event.clientY;
      this.dragState.endMouseLeft = event.clientX;
    },
    /**
     * 处理鼠标放开事件
     */
    handleMouseUp() {
      // 如果值发生了改变

      if (this.newWidth && this.newHeight) {
        this.exammarkPoint.points.width = parseInt(
          this.newWidth * this.proportion
        );
        this.exammarkPoint.points.height = parseInt(
          this.newHeight * this.proportion
        );
        this.exammarkPoint.points.top = parseInt(this.newTop * this.proportion);
        this.exammarkPoint.points.left = parseInt(
          this.newLeft * this.proportion
        );
      }
      this.setIndexQuestion(this.exammarkPoint.points);
      // 移除鼠标移动事件
      document.removeEventListener("mousemove", this.handleMouseMove);
      // 移除鼠标放开事件
      document.removeEventListener("mouseup", this.handleMouseUp);
      // 允许用户选择网页中文字;
      document.onselectstart = null;
      // 允许用户拖动元素
      document.ondragstart = null;
    },
    //鼠标长按移动盒子 start
    mouseout() {
      this.dragType = false;
      // this.indexQuestion = {};
    },
    // 当鼠标指针在指定的元素中移动时
    mousemoveTest($event) {
      if (!this.dragType) {
        return;
      }
      const { endMouseTop, endMouseLeft } = this.mouseDragState;
      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - $event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - $event.clientX) * 100).toString(), 10) / 100
      );
      let pointsArea = this.exammarkPoint.points;
      if (endMouseLeft <= $event.clientX) {
        pointsArea.left = pointsArea.left + distanceLeft * this.proportion;
      } else {
        pointsArea.left = pointsArea.left - distanceLeft * this.proportion;
      }
      //  最小值限制 start
      if (endMouseTop <= $event.clientY) {
        pointsArea.top = pointsArea.top + distance * this.proportion;
      } else {
        pointsArea.top = pointsArea.top - distance * this.proportion;
      }
      if (pointsArea.top <= 1) {
        pointsArea.top = 1;
      }
      if (pointsArea.left <= 1) {
        pointsArea.left = 1;
      }
      // 最小值限制 end
      // 最大值限制 start
      // 到右侧最大距离
      if (
        pointsArea.left + pointsArea.width >=
        this.viewWidth * this.proportion
      ) {
        pointsArea.left =
          this.viewWidth * this.proportion - pointsArea.width - 1;
      }
      // 到下方最大距离
      if (
        pointsArea.top + pointsArea.height >=
        this.viewHeight * this.proportion
      ) {
        pointsArea.top =
          this.viewHeight * this.proportion - pointsArea.height - 1;
      }
      // console.log(pointsArea);
      this.exammarkPoint.points = pointsArea;
      this.setIndexQuestion(this.exammarkPoint.points);
      // 更新鼠标最后移动的位置（Y轴）
      this.mouseDragState.endMouseTop = $event.clientY;
      this.mouseDragState.endMouseLeft = $event.clientX;
    },
    // 按下触发
    mousedownTest($event) {
      // console.log(this.exammarkPoint.points);
      this.mouseDragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: $event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: $event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: $event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: $event.clientX,
      };
      this.dragType = true;
    },
    // 抬起触发
    async mouseupTest() {
      this.dragType = false;
    },
    // 获取线条内容
    getline(v, type) {
      let val = (v.points[type] / this.proportion).toFixed(2) + "px";
      return val;
    },
    // 获取试题图片
    getpointArea() {
      // console.log(this.question);
      let data = {
        sheetDetailId: this.question.id,
        id: "",
      };
      pointArea(data)
        .then((res) => {
          this.imgUrl = res.data.data.path;
          this.onloadImg();
        })
        .catch(() => {});
    },
    // 鼠标移动
    mousemove(e) {
      // if (e) {
      //   return;
      // }
      this.drawRect(e);
    },
    // 设置除了当前线条的所有线条
    setAllLineData() {
      // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      //设置线条颜色，必须放在绘制之前
      this.ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
      this.exammarkPointItem.map((item, index) => {
        if (item.tId != this.pointId) {
          this.setStrokeRect(item.points);
          this.setLineName(item.points, item, index);
        }
      });
    },
    // 加载答题卡
    async onloadImg() {
      // this.indexSheetMark = JSON.parse(
      //   JSON.stringify(this.GET_INDEX_SHEET_MARK)
      // );

      // this.indexImgUrl = this.indexSheetMark.urlAdjust;
      this.setImg();
      // 获取cavas需要渲染的当前列表
      // this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      await this.setImg();

      // 获取cavas需要渲染的当前列表
      // this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      // console.log(JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST)));
      // console.log(this.GET_SHEET_LINE_LIST);
      // 设置cavas参数
      this.canvas = this.$refs.lineCanvas;
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width = this.viewWidth;
      this.canvas.height = this.oldHeight / (this.oldWidth / this.viewWidth);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
    },
    // 绘制图形
    drawRect(e) {
      // console.log(this.flag);
      if (this.flag) {
        let x = this.x;
        let y = this.y;
        let offsetX = e.offsetX;
        let offsetY = e.offsetY;
        if (x < 1) {
          x = 1;
        }
        if (y < 1) {
          y = 1;
        }
        if (x + 1 > this.viewWidth) {
          x = x - 1;
        }
        if (y + 1 > this.viewHeight) {
          y = y - 1;
        }
        if (offsetX < 1) {
          offsetX = 1;
        }
        if (offsetY < 1) {
          offsetY = 1;
        }
        // console.log(offsetX + 5);
        // console.log(this.viewWidth + Number(this.oldLeft));
        if (offsetX + 1 > this.viewWidth) {
          offsetX = this.viewWidth - 1;
        }

        if (offsetY + 1 > this.viewHeight) {
          offsetY = this.viewHeight - 1;
        }
        // console.log(this.viewHeight);
        // console.log(offsetY + "----------");
        // console.log(this.viewHeight);
        this.lineData = {
          //  x轴位置
          left: x,
          //  y轴位置
          top: y,
          //  绘制出来的盒子宽度
          width: offsetX - x,
          //  绘制出来的盒子高度
          height: offsetY - y,
        };
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "rgba(0, 0, 255, 1)";
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.beginPath();
        this.ctx.strokeRect(x, y, offsetX - x, offsetY - y);
        this.setAllLineData();
      }
    },
    // 图片加载
    callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      // 获取canvas对象
      const imgCanvas = document.getElementById("imgCanvas");
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.markSheet);
      // 设置src值
      img.src = encodeURI(this.imgUrl);

      const viewWidth = this.$refs.markSheet.clientWidth;
      this.oldLeft = this.$refs.markSheet.clientWidth;
      // this.$refs.locationBox.style.left =
      //   (this.$refs.markSheet.clientWidth * 0.1) / 2 + "px";
      this.viewWidth = viewWidth;
      const _this = this;

      //设置canvas属性 oldWidth oldHeight viewWidth
      await this.callFunction(img);
      _this.oldWidth = img.width;
      _this.oldHeight = img.height;
      // 当前比例
      _this.proportion = _this.oldWidth / _this.viewWidth;
      //重新定义图片宽高
      img.height = (img.height * viewWidth) / img.width;
      img.width = viewWidth;

      imgCanvas.width = viewWidth;
      _this.viewHeight = img.height;
      imgCanvas.height = img.height;
      imgCanvas.style.backgroundSize = `${img.width}px ${img.height}px`;
      imgCanvas.style.backgroundImage = 'url("' + img.src + '")';

      // 设置装cavans的盒子大小
      _this.$refs.locationBox.style.height = img.height + "px";
      _this.$refs.locationBox.style.width = viewWidth + "px";
    },
    // 设置坐标点
    setCoordinatePoint() {
      // 坐标点可能是负值 需要重新计算传给后台 this.sheetLineList
      // console.log(this.lineData);
      if (
        !this.lineData.left &&
        !this.lineData.top &&
        !this.lineData.width &&
        !this.lineData.height
      ) {
        return;
      }
      let lineData = Object.assign({}, this.lineData);
      if (lineData.width < 0) {
        lineData.width = Math.abs(lineData.width);
        lineData.left = lineData.left - lineData.width;
      }
      if (lineData.height < 0) {
        lineData.height = Math.abs(lineData.height);
        lineData.top = lineData.top - lineData.height;
      }
      // 如果长宽都小于10 就不添加
      // if (lineData.height < 10 && lineData.width < 10) {
      //   return;
      // }
      this.lineData = lineData;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.beginPath();

      this.ctx.strokeRect(
        lineData.left,
        lineData.top,
        lineData.width,
        lineData.height
      );
      // 根据比例恢复坐标点;
      this.setDefaultPoint(this.lineData);
      this.setAllLineData();
    },
    // 根据比例恢复坐标点; 并且判断当前类型进行逻辑处理
    setDefaultPoint() {
      // console.log(2222222);
      let data = Object.assign({}, this.lineData);

      data.left = parseInt(data.left * this.proportion);
      data.top = parseInt(data.top * this.proportion);
      data.width = parseInt(data.width * this.proportion);
      data.height = parseInt(data.height * this.proportion);
      this.lineData = {};
      this.setIndexQuestion(data);
    },
    setIndexQuestion(data) {
      // console.log(33333333333);
      this.exammarkPoint.points = data;
      this.exammarkPointItem = this.exammarkPointItem.map((item) => {
        if (item.tId == this.exammarkPoint.tId) {
          Object.assign(item, this.exammarkPoint);
        }
        return item;
      });
      let exammarkPointItem = JSON.parse(
        JSON.stringify(this.exammarkPointItem)
      );
      exammarkPointItem = exammarkPointItem.map((item) => {
        if (item.points) {
          item.points = JSON.stringify(item.points);
        } else {
          item.points = "";
        }
        if (item.tId == this.exammarkPoint.tId) {
          setStore({
            name: "exammarkPoint",
            type: 1,
            content: item,
          });
        }
        return item;
      });
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
      // console.log(this.exammarkPointItem);
      setStore({
        name: "exammarkPointItem",
        type: 1,
        content: exammarkPointItem,
      });
    },
    // 鼠标抬起
    mouseupAll() {
      // console.log("触发了");
      // 如果是非抬起状态  不管
      this.flag = false;
      clearTimeout(this.setClickTime);
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return true;
      };
      // 判断下重复请求
      let time = new Date().getTime();
      if (this.time && time - this.time < 1000) {
        return;
      }
      this.time = new Date().getTime();
      // 鼠标抬起后 先判断是否存在值
      this.setCoordinatePoint();
      this.flag = false;
    },
    setLineName(data, item, index) {
      // 文字
      this.ctx.fillStyle = "red";
      this.ctx.font = "16px Arial";
      let str = item.name ? item.name : "给分点" + (index + 1);
      this.ctx.fillText(
        str,
        data.left / this.proportion + 4,
        data.top / this.proportion + 16
      );
    },
    // 根据传入的点位绘制cavas设置点位
    setStrokeRect(postion) {
      this.ctx.strokeRect(
        postion.left / this.proportion,
        postion.top / this.proportion,
        postion.width / this.proportion,
        postion.height / this.proportion
      );
    },
    mousemoveAll(e) {
      // console.log(e);
      if (this.flag) {
        // 获取当前盒子 在移动鼠标的时候禁用复制
        let locationBox = this.$refs.locationBox;
        let { scrollTop, scrollLeft } = document.documentElement;
        // let { left } = this.$refs.locationBox.style;
        // left = Number(left.substr(0, left.length - 2));
        let bodyStyle = document.getElementById("kt_content").offsetLeft;
        let clientX = e.x + scrollLeft - bodyStyle - 73;
        let clientY = e.y - 280 + scrollTop;
        let divx1 = locationBox.offsetLeft;
        let divy1 = locationBox.offsetTop;
        let divx2 = locationBox.offsetLeft + locationBox.offsetWidth;
        let divy2 = locationBox.offsetTop + locationBox.offsetHeight;

        if (
          clientX < divx1 ||
          clientX > divx2 ||
          clientY < divy1 ||
          clientY > divy2
        ) {
          let mos = {};

          mos.offsetX = Number(e.x) - 73 - bodyStyle;
          // 如果Y小于小于当前盒子的位置  直接赋为-1
          // console.log(e.y);
          if (e.y + scrollTop < 280) {
            mos.offsetY = -1;
          } else {
            mos.offsetY = Number(e.y) - 280 + scrollTop;
          }

          if (clientX < divx1) {
            mos.offsetX = 0;
            mos.offsetY = Number(e.y) - 280 + scrollTop;
          }

          this.drawRect(mos);
        }
      } else {
        // console.log("1111");
      }
    },
    // 鼠标落下
    mousedown(e) {
      // console.log(this.exammarkPoint.points);
      if (this.exammarkPoint.points.width) return;
      this.flag = true;
      this.indexHiddenBox = {};
      this.x = e.offsetX; // 鼠标落下时的X
      this.y = e.offsetY; // 鼠标落下时的Y
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return false;
      };
    },
    init() {
      this.question = getStore({ name: "question", type: 1 });
      this.pointId = getStore({
        name: "tId",
        type: 1,
      });
      this.pointId = Number(this.pointId);
      let exammarkPointItem = getStore({
        name: "exammarkPointItem",
        type: 1,
      });
      exammarkPointItem = JSON.parse(JSON.stringify(exammarkPointItem));
      this.exammarkPointItem = exammarkPointItem.map((item) => {
        // if (item.points) {
        //   item.points = JSON.parse(item.points);
        // } else {
        //   item.points = {};
        // }
        item.tId = Number(item.tId);
        if (item.tId == this.pointId) {
          this.exammarkPoint = JSON.parse(JSON.stringify(item));
        }
        return item;
      });
    },
    //获取考试基本信息
    getObj() {
      getObj(this.examInfo.examId).then((res) => {
        this.examInformation = res.data.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ExamQuestionArea {
  padding-top: 18px;
  .img-box {
    padding: 18px;
    background-color: #f9fafc;
  }
  .cavans-box {
    // height: calc(100vh - 200px);
    position: relative;
    width: 800px;
    // margin-top: 40px;
    // padding-bottom: 80px;
    canvas {
      position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      // top: 40px;
    }
  }
  .score-item {
    margin-top: 18px;
    padding: 18px;
    border-bottom: 1px solid #edeff0;
    .el-button {
      margin-left: 18px;
    }
    .el-radio {
      margin-bottom: 0;
    }
  }
  .details-box {
    background-color: #ffffff;
  }
  .edit-operating-box.operating-box {
    border: 1px solid #f45454;
    background: rgba(255, 0, 0, 0.2);
    opacity: 1;
    z-index: 20;
    cursor: move;

    .circle-box {
      cursor: all-scroll;
    }

    .circle-box {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #ffffff;
      border-radius: 50%;
      border: 2px solid #f45454;
      z-index: 10;
    }

    // circle-left circle-right circle-top circle-bottom
    .circle-left {
      top: 50%;
      left: -6px;
      cursor: ew-resize;
    }

    .circle-right {
      top: 50%;
      right: -6px;
      cursor: ew-resize;
    }

    .circle-top {
      left: 50%;
      top: -6px;
      cursor: s-resize;
    }

    .circle-bottom {
      left: 50%;
      bottom: -6px;
      cursor: s-resize;
    }
  }
  // .mark-sheet
  .operating-box {
    position: absolute;
    // border: 1px solid #f45454;

    opacity: 0;

    &:hover {
      opacity: 1;
      z-index: 19;

      .operating-btn-box {
        opacity: 1;
        // visibility: unset;
        display: block;
      }
    }

    .operating-btn-box {
      opacity: 0;
      position: absolute;
      left: -1px;
      bottom: -24px;
      white-space: nowrap;
      background: rgba(0, 0, 0, 0.6);
      padding: 5px 8px;
      line-height: 12px;
      display: none;

      // visibility: hidden;
      .el-button {
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
}
</style>
