<template>
  <div class="detailsHeader">
    <div class="goback" @click="goBack()">
      <img
        v-if="!hiddenBack"
        class="backImg"
        src="@/assets/newExam/back.svg"
        alt=""
      />您的位置： {{ examInformation.name }}{{ routerName }}
      <template v-if="$route.query.subjectId"
        >/ {{ $route.query.subjectId | setName(subjectOptions) }}</template
      >
    </div>
    <div v-if="showPopver" class="title-right">
      <span v-if="examInformation.ruleId > 0" class="title-tips title-type-new">
        新高考
      </span>
      <span v-else class="title-tips">普通考试</span>

      <el-popover
        placement="bottom"
        width="360"
        trigger="hover"
        style="margin-left: 18px"
        popper-class="tips-details"
        :open-delay="300"
        :close-delay="0"
      >
        <div class="details-tips-box">
          <span v-if="examInformation.ruleId > 0" class="details-tips">
            新高考
          </span>
          <div class="name-box">
            <span v-if="examInformation.status == 3" class="status">
              已统计
            </span>
            <span v-if="examInformation.status == 2" class="status status-succ">
              已结束
            </span>
            <span v-if="examInformation.status == 1" class="status status-ing">
              进行中
            </span>
            <p class="title">{{ examInformation.name }}</p>
          </div>
          <div style="margin-bottom: 18px">
            <span class="title-tips">
              {{ examInformation.level | setName(levelOptions) }}
            </span>
            <span class="title-tips">{{ examInformation.year }}级</span>
            <span class="title-tips">
              {{ examInformation.type | setName(examTypeOptions) }}
            </span>
            <span class="title-tips">
              {{ examInformation.examDate }}
            </span>
          </div>
          <div class="security-box">
            <img
              v-if="examInformation.securityLevel == 1"
              src="@/assets/newExam/icon-safety.svg"
              alt=""
            />
            <img
              v-if="examInformation.securityLevel == 2"
              src="@/assets/newExam/icon-safety-err.svg"
              alt=""
            />
            风险等级：
            <template v-if="examInformation.securityLevel == 1">低</template>
            <template v-if="examInformation.securityLevel == 2"
              >高，禁止数据发布</template
            >
          </div>
        </div>

        <div slot="reference" class="title-tips" style="cursor: pointer">
          {{ examInformation.level | setName(levelOptions) }}
          <span class="shu">|</span>
          {{ examInformation.year }}
          <span class="shu">|</span>
          {{ examInformation.type | setName(examTypeOptions) }}
          <span class="shu">|</span>
          <span
            class="fx"
            :class="{ error: examInformation.securityLevel == 2 }"
          >
            {{
              examInformation.securityLevel | setName(examSecurityLevelOptions)
            }}
          </span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
// import {}
import {
  examTypeOptions,
  examSecurityLevelOptions,
  levelOptions,
  // gradeHighOptions,
  // gradeMiddleOptions,

  // examReportAutoSendOptions,
} from "@/core/util/constdata";
import { filterSubject } from "@/core/util/util";
export default {
  name: "DetailsHeader",
  components: {},
  props: {
    hiddenBack: {
      type: Boolean,
      default: false,
    },
    showPopver: {
      type: Boolean,
      default: true,
    },
    routerName: {
      type: String,
      default: function () {
        return " / " + this.$route.meta.title;
      },
    },
    // 如果需要跳转到指定位置
    routerUrl: {
      type: String,
      default: "",
    },
    // 指定路由地址传参
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      levelOptions: levelOptions(),
      subjectOptions: filterSubject(),
      examSecurityLevelOptions: examSecurityLevelOptions,
      examTypeOptions: examTypeOptions,
      examInformation: {},
    };
  },
  watch: {
    "$parent.examInformation": {
      deep: true,
      handler() {
        if (this.$parent && this.$parent.examInformation) {
          this.examInformation = JSON.parse(
            JSON.stringify(this.$parent.examInformation)
          );
        }
      },
    },
  },
  created() {
    if (this.$parent && this.$parent.examInformation) {
      this.examInformation = JSON.parse(
        JSON.stringify(this.$parent.examInformation)
      );
    }
  },
  methods: {
    goBack() {
      if (this.routerUrl) {
        this.$router.push({
          name: this.routerUrl,
          query: this.query,
        });
      } else {
        this.$router.go(-1);
      }
    },
    // init(value) {
    //   this.examInformation = JSON.parse(JSON.stringify(value));
    // },
  },
};
</script>
<style lang="scss">
.tips-details {
  padding: 0;
  .details-tips-box {
    padding: 24px;
    position: relative;
    overflow: hidden;
    .security-box {
      display: flex;
      align-items: center;
      line-height: 14px;
      img {
        margin-right: 8px;
      }
    }
    .name-box {
      margin-bottom: 18px;
      align-items: flex-start;
      display: flex;
      span {
        flex-shrink: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
    .title-tips {
      padding: 4px 8px;
      color: #2474ed;
      background: #f3f8ff;
      border-radius: 4px;
      font-size: 12px;
      // display: flex;
    }
    .title-tips + .title-tips {
      margin-left: 8px;
    }
    .details-tips {
      position: absolute;
      right: -43px;
      top: 16px;
      transform: rotate(45deg);
      font-size: 12px;
      background: #fff8f3;
      width: 100px;
      padding: 4px 0 2px 18px;
      color: #ff7a00;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #0a1119;
      padding: 4px 0;
      line-height: 14px;
      // vertical-align: middle;
    }
    .status {
      font-size: 12px;
      background-color: #ffab00;
      color: white;
      border-radius: 3px;
      margin-right: 10px;
      padding: 3px 8px;
    }
    .status-succ {
      background-color: $success-color;
    }

    .status-ing {
      background-color: $primary-color;
    }
  }
}
</style>
<style scoped lang="scss">
.detailsHeader {
  padding: 16px 18px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-tips {
    padding: 6px 18px;
    background: #f3f8ff;
    border-radius: 4px;
    color: #2474ed;
    font-size: 14px;
    // display: flex;
    // align-items: center;
    .error {
      color: #ff0000;
      // margin-bottom: 0;
    }

    .shu {
      color: #3e4551;
      opacity: 0.14;
      font-size: 12px;
      margin: 0 3px;
    }
  }
  .title-right {
    display: flex;
    align-items: center;
  }

  .title-type-new {
    background: #fff8f3;
    color: #ff7a00;
  }
  .backImg {
    margin-right: 8px;
    vertical-align: text-bottom;
  }
  .goback {
    cursor: pointer;
  }
}
</style>
